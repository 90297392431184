<template>
  <v-row id="">
    <v-col cols="12">
      <v-card class="pa-2 d-flex flex-column position-relative" border>
        <v-toolbar
          height="50"
          class="mb-2 rounded"
          color="blue-grey-darken-1"
          density="compact"
        >
          <v-toolbar-title class="text-subtitle-2 text-caption">Фильтр</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon="mdi-filter-cog-outline"
            size="small"
            @click="openFilter = !openFilter"
          ></v-btn>
        </v-toolbar>

        <v-expand-transition>
          <div v-show="!openFilter">
            <v-row>
              <v-col cols="12" md="3">
                <v-card class="pa-2 d-flex flex-column position-relative elevation-0">
                  <v-toolbar
                    height="50"
                    class="mb-2 rounded"
                    color="blue-grey-darken-1"
                    density="compact"
                  >
                    <v-toolbar-title class="text-subtitle-2 text-caption"
                      >Поиск по категории</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-select
                    v-model="checkItems"
                    label="Выберите категорию"
                    :items="items"
                    item-title="name"
                    item-value="id"
                    class="pl-4 pr-4 pt-2 pb-4 add_category"
                    variant="underlined"
                    hide-details
                    required
                    return-object
                    density="compact"
                    :disabled="search != ''"
                    @update:modelValue="onChangeItem"
                  >
                    <template v-slot:no-data>
                      <div class="px-4">Ничего не найдено</div>
                    </template>
                  </v-select>
                  <v-select
                    v-if="subItems.length > 0"
                    v-model="checkSubItems"
                    label="Выберите подкатегорию"
                    :items="subItems"
                    item-title="name"
                    item-value="id"
                    class="pl-4 pr-4 add_category"
                    variant="underlined"
                    hide-details
                    density="compact"
                    :disabled="search != ''"
                    return-object
                    @update:modelValue="onChangeSubItem"
                  >
                    <template v-slot:no-data>
                      <div class="px-4">Ничего не найдено</div>
                    </template>
                  </v-select>
                  <div class="d-flex ga-4 justify-space-between">
                    <v-btn
                      class="text-none mt-4 ml-4 mr-4 elevation-0"
                      color="green"
                      size="small"
                      :disabled="search != ''"
                      @click="getAllQuestionsPagination"
                      >Применить</v-btn
                    >
                    <v-btn
                      class="text-none mt-4 ml-4 mr-4 elevation-0"
                      color="grey"
                      size="small"
                      @click="catReset"
                      >Сбросить фильтр</v-btn
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="pa-2 d-flex flex-column position-relative elevation-0">
                  <v-toolbar
                    height="50"
                    class="mb-2 rounded"
                    color="blue-grey-darken-1"
                    density="compact"
                  >
                    <v-toolbar-title class="text-subtitle-2 text-caption"
                      >Поиск по вопросу</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-text-field
                    v-model="search"
                    label="Поиск"
                    variant="underlined"
                    hide-details
                    class="pl-4 pr-4 pt-2 add_category"
                    density="compact"
                    v-on:keyup.enter="searchQuestions"
                  ></v-text-field>
                  <div class="d-flex ga-4 justify-space-between">
                    <v-btn
                      class="text-none mt-4 ml-4 mr-4 elevation-0"
                      color="green"
                      size="small"
                      :disabled="!search || search.length < 4"
                      @click="searchQuestions"
                      >Применить</v-btn
                    >
                    <v-btn
                      class="text-none mt-4 ml-4 mr-4 elevation-0"
                      color="grey"
                      size="small"
                      @click="searchReset"
                      >Сбросить фильтр</v-btn
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" md="3">
      <v-card class="pa-2 d-flex flex-column position-relative" border fixed>
        <v-toolbar
          height="50"
          class="mb-2 rounded"
          color="blue-grey-darken-1"
          density="compact"
        >
          <v-toolbar-title class="text-subtitle-2 text-caption">Вопросы</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-alert
          v-if="filteredList.length <= 0 || message != ''"
          class="mt-5 mb-5"
          role="alert"
          transition="slide-x-transition"
        >
          {{ message }}
        </v-alert>

        <v-list lines="two" v-if="filteredList.length > 0 && message == ''">
          <v-list-item
            v-for="(item, index) in filteredList"
            :key="index"
            :class="item.editingSub ? 'bg-blue-grey-lighten-5' : ''"
            @click="
              this.searchResult.length > 0
                ? (fuzeAnswer(item, index), hideButtonAndScroll())
                : (fullAnswer(item, index), hideButtonAndScroll())
            "
          >
            <v-list-item-title
              class="d-block text-wrap text-subtitle-2"
              v-html="item.text"
            ></v-list-item-title>
            <v-list-item-subtitle v-html="item.text"> </v-list-item-subtitle>
            <template v-slot:append>
              <v-btn
                color="grey-lighten-1"
                icon="mdi-arrow-right-circle-outline"
                variant="text"
              ></v-btn>
            </template>
            <v-divider class="mt-2"></v-divider>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>

    <v-col cols="12" md="9" transition="slide-x-reverse-transition">
      <div id="questBlock">
        <transition>
          <div
            v-show="Object.keys(answers).length"
            transition="slide-x-reverse-transition"
          >
            <v-card
              id="answerBlock"
              class="pa-2 d-flex flex-column position-relative"
              border
            >
              <v-card-title
                class="d-block text-wrap text-subtitle-1 font-weight-bold markFloat"
                v-html="answers.text"
              >
              </v-card-title>
              <v-card-text v-html="answers.answer"></v-card-text>
              <v-btn
                v-if="Object.keys(answers).length > 0 && answers.sub_questions.length > 0"
                class="text-none"
                size="small"
                @click="isOpen = !isOpen"
                flat
                ><span style="font-size: 24px; transform: rotate(-90deg)"
                  >&lsaquo;</span
                ></v-btn
              >
            </v-card>

            <v-row v-if="isOpen" class="mt-3">
              <v-col
                cols="12"
                md="12"
                v-for="(item, index) in answers.sub_questions"
                :key="index"
              >
                <v-card class="pa-2 d-flex flex-column position-relative" border>
                  <answer :answer-data="item"></answer>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </transition>
      </div>
    </v-col>
  </v-row>

  <v-pagination
    v-model="filter.page"
    :length="filter.pages"
    color="blue-grey-darken-1"
    density="comfortable"
    class="mt-12 mw"
    :disabled="search != ''"
  ></v-pagination>
  <div class="d-flex flex-row justify-center">
    <v-select
      class="sizePagination"
      v-model="filter.size"
      hide-details
      @update:model-value="sizing"
      max-width="65px"
      :items="[5, 10, 15, 25, 50, 100]"
      variant="underlined"
    ></v-select>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import answer from "../components/Answer.vue";

export default {
  name: "Home",
  components: { answer },
  data() {
    return {
      openFilter: false,
      content: [],
      filter: {
        category_id: "",
        subcategory_id: "",
        page: 1,
        pages: 1,
        size: 10,
      },
      sortOrder: "desc",
      addItemsQuestion: [],
      items: [],
      subItems: [{ id: 0, name: "Выбраны все категории" }],
      checkItems: "",
      checkSubItems: "",
      answers: [],
      search: "",
      searchText: "",
      searchAnswer: "",
      isOpen: false,
      searchResult: [],
      message: "",
      indexItem: null,
      editingSub: false,
      searchError: "",
    };
  },
  watch: {
    "filter.page"(old, value) {
      if (old != value) {
        (this.indexItem = null), this.getAllQuestionsPagination();
      }
    },
    indexItem: {
      handler(i, y) {
        if (y != null) {
          this.filteredList[y].editingSub = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    filteredList() {
      if (this.searchResult.length == 0) {
        return this.content;
      } else {
        return this.searchResult;
      }
    },
  },
  methods: {
    sizing(event) {
      localStorage.setItem("size", event);
      this.getAllQuestionsPagination();
    },
    hideButtonAndScroll() {
      document
        .getElementById("questBlock")
        ?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    },
    removeHtmlTags(htmlString) {
      const tmp = document.createElement("DIV");
      tmp.innerHTML = htmlString;
      return tmp.textContent || tmp.innerText || "";
    },
    async searchQuestions() {
      if (this.search) {
        this.answers = [];

        this.searchError = "";

        userService
          .searchQuestionsFuzzy(this.search)
          .then((res) => {
            if (res.data.length == 0) {
              this.message = "Ни чего не найдено. Попробуйте изменить запрос.";
            } else {
              this.message = "";
              this.answers = [];
              this.searchResult = [];

              res.data.forEach((el) => {
                const indi = el.match_positions;
                if (indi.length >= 0) {
                  indi.forEach((i) => {
                    String.prototype.splice = function (start, newSubStr) {
                      if (start < 0) {
                        start = this.length + start;
                        start = Math.max(start, 0);
                      }
                      return this.slice(0, start) + newSubStr + this.slice(start);
                    };

                    const multiInsert = (str, ...insertInstructions) => {
                      return insertInstructions.reduce((current, [position, insert]) => {
                        return current.splice(position, insert);
                      }, str);
                    };

                    for (const [key, val] of Object.entries(el)) {
                      if (key == i.field) {
                        Object.defineProperty(el, i.field, {
                          value: multiInsert(
                            val,
                            [i.end, "</mark>"],
                            [i.start, "<mark>"]
                          ),
                        });
                      }
                    }
                  });
                }
                this.searchResult.push(el);
              });
            }
          })
          .catch(() => {
            this.message = "Ни чего не найдено. Попробуйте изменить запрос.";
          });
      }
    },
    catReset() {
      this.checkItems = this.items[0];
      this.checkSubItems = this.subItems[0];
      this.subItems = [{ id: 0, name: "Выбраны все категории" }];
      this.filter = {
        category_id: "",
        subcategory_id: "",
        page: 1,
        pages: 1,
        size: localStorage.getItem("size") ? localStorage.getItem("size") : 10,
      };
      this.getAllQuestionsPagination();
      this.answers = [];
    },
    searchReset() {
      this.search = "";
      this.message = "";
      this.searchResult = [];
      this.searchError = "";
      this.getAllQuestionsPagination();
      this.answers = [];
    },
    fuzeAnswer(item, index) {
      if (this.searchResult.length > 1) {
        this.indexItem = index;
      }
      this.filteredList[index].editingSub = true;
      this.answers = item;
    },
    fullAnswer(item, index) {
      this.content[index].editingSub = true;
      this.indexItem = index;

      const answer = {
        question_id: item.id,
      };
      userService
        .postFullAmswer(answer)
        .then((res) => {
          this.answers = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllQuestionsPagination() {
      this.indexItem = null;
      await userService
        .getAllQuestionsPagination(this.filter)
        .then(async (res) => {
          this.content = res.data.items;
          this.filter.pages = res.data.pages;
          if (Number(this.$route.query.page) > res.data.pages) {
            this.$router.push(`/home?page=1`);
            this.filter.page = 1;
          } else {
            this.$router.push(`/home?page=${this.filter.page}`);
          }
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.$store.state.auth.status.loggedIn = false;
          this.$router.push("/login");
        });
    },
    async getCategories() {
      await userService
        .getCategories()
        .then((res) => {
          this.addItemsQuestion = [...res.data];
          this.items = res.data;
          this.items.push({
            id: 0,
            name: "Выбраны все категории",
            number: 0,
            subcategories: [],
          });
          this.items.sort((a, b) => {
            const sortOrder = this.sortOrder === "desc" ? 1 : -1;
            if (a.number > b.number) {
              return sortOrder;
            } else if (a.number < b.number) {
              return -sortOrder;
            } else {
              return 0;
            }
          });
          this.checkItems = this.items[0];

          this.items[0].subcategories.forEach((element) => {
            this.subItems.push(element);
          });

          this.checkSubItems = this.subItems[0];
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.$store.state.auth.status.loggedIn = false;
          this.$router.push("/login");
        });
    },
    onChangeItem(entry) {
      (this.subItems = [{ id: 0, name: "Выбраны все категории" }]),
        entry.subcategories.forEach((element) => {
          this.subItems.push(element);
        });

      this.checkSubItems = this.subItems[0];

      if (entry.id != 0) {
        this.filter.category_id = `category_id=${entry.id}&`;
        this.filter.subcategory_id = "";
      } else {
        this.filter.category_id = "";
      }
    },
    onChangeSubItem(entry) {
      if (entry.id != 0) {
        this.filter.subcategory_id = `subcategory_id=${entry.id}&`;
      } else {
        this.filter.subcategory_id = "";
      }
    },
  },
  mounted() {
    let size = localStorage.getItem("size");

    if (size != null) {
      this.filter.size = size;
    }

    if (this.$route.query.page != undefined && !isNaN(this.$route.query.page)) {
      this.filter.page = Number(this.$route.query.page);
    } else {
      this.filter.page = 1;
    }
    this.getAllQuestionsPagination();
    this.getCategories();
  },
};
</script>

<style scoped>
.mw {
  max-width: 560px;
  margin: 0 auto;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.sizePagination {
  font-size: 12px !important;
}
</style>
